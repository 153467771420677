
/*
*   CSS file with Bootstrap grid classes for screens bigger than 1600px. Just add this file after the Bootstrap CSS file and you will be able to juse col-xl, col-xl-push, hidden-xl, etc.
*
*   Author: Marc van Nieuwenhuijzen
*   Company: WebVakman
*   Site: WebVakman.nl
*
*/

@media (min-width: 1200px) and (max-width: 1599px) {
    .hidden-lg {
        display: none !important;
    }
}


.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block,
.visible-xl{
    display: none !important;
}



@media (min-width: 1600px) {
    .container {
        width: 1570px;
    }

    .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
        float: left;
    }

    .col-xl-12 {
        width: 100%;
    }

    .col-xl-11 {
        width: 91.66666667%;
    }

    .col-xl-10 {
        width: 83.33333333%;
    }

    .col-xl-9 {
        width: 75%;
    }

    .col-xl-8 {
        width: 66.66666667%;
    }

    .col-xl-7 {
        width: 58.33333333%;
    }

    .col-xl-6 {
        width: 50%;
    }

    .col-xl-5 {
        width: 41.66666667%;
    }

    .col-xl-4 {
        width: 33.33333333%;
    }

    .col-xl-3 {
        width: 25%;
    }

    .col-xl-2 {
        width: 16.66666667%;
    }

    .col-xl-1 {
        width: 8.33333333%;
    }

    .col-xl-pull-12 {
        right: 100%;
    }

    .col-xl-pull-11 {
        right: 91.66666667%;
    }

    .col-xl-pull-10 {
        right: 83.33333333%;
    }

    .col-xl-pull-9 {
        right: 75%;
    }

    .col-xl-pull-8 {
        right: 66.66666667%;
    }

    .col-xl-pull-7 {
        right: 58.33333333%;
    }

    .col-xl-pull-6 {
        right: 50%;
    }

    .col-xl-pull-5 {
        right: 41.66666667%;
    }

    .col-xl-pull-4 {
        right: 33.33333333%;
    }

    .col-xl-pull-3 {
        right: 25%;
    }

    .col-xl-pull-2 {
        right: 16.66666667%;
    }

    .col-xl-pull-1 {
        right: 8.33333333%;
    }

    .col-xl-pull-0 {
        right: auto;
    }

    .col-xl-push-12 {
        left: 100%;
    }

    .col-xl-push-11 {
        left: 91.66666667%;
    }

    .col-xl-push-10 {
        left: 83.33333333%;
    }

    .col-xl-push-9 {
        left: 75%;
    }

    .col-xl-push-8 {
        left: 66.66666667%;
    }

    .col-xl-push-7 {
        left: 58.33333333%;
    }

    .col-xl-push-6 {
        left: 50%;
    }

    .col-xl-push-5 {
        left: 41.66666667%;
    }

    .col-xl-push-4 {
        left: 33.33333333%;
    }

    .col-xl-push-3 {
        left: 25%;
    }

    .col-xl-push-2 {
        left: 16.66666667%;
    }

    .col-xl-push-1 {
        left: 8.33333333%;
    }

    .col-xl-push-0 {
        left: auto;
    }

    .col-xl-offset-12 {
        margin-left: 100%;
    }

    .col-xl-offset-11 {
        margin-left: 91.66666667%;
    }

    .col-xl-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-xl-offset-9 {
        margin-left: 75%;
    }

    .col-xl-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-xl-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-xl-offset-6 {
        margin-left: 50%;
    }

    .col-xl-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-xl-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-xl-offset-3 {
        margin-left: 25%;
    }

    .col-xl-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-xl-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-xl-offset-0 {
        margin-left: 0;
    }

    .visible-xl {
        display: block !important;
    }

    table.visible-xl {
        display: table;
    }

    tr.visible-xl {
        display: table-row !important;
    }

    th.visible-xl, td.visible-xl {
        display: table-cell !important;
    }

    .visible-xl-block {
        display: block !important;
    }

    .visible-xl-inline {
        display: inline !important;
    }

    .visible-xl-inline-block {
        display: inline-block !important;
    }

    .hidden-xl {
        display: none !important;
    }
}


/**************************************
*
*   width, height, margin, padding classes
*   for nice and sweet stuff
*   in a Bootstrap 4 way
*
***************************************/

.w-25 {
    width: 25% !important;
}
  
.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

/*====================================
=            more margins            =
====================================*/
.mt-10,
.my-10 {
    margin-top: 6rem !important;
}

.mr-10,
.mx-10 {
    margin-right: 6rem !important;
}

.mb-10,
.my-10 {
    margin-bottom: 6rem !important;
}

.ml-10,
.mx-10 {
    margin-left: 6rem !important;
}



.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.text-underligné,
.text-underlined{
    text-decoration: underline;
}

.input-group-sm .input-group-btn .btn{
    padding: 4px, 16px;
}


@media (max-width: $screen-xs){
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow: visible;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
    }
}
.help-block{
    margin-bottom: 0;
    font-size: 0.95em;
}
