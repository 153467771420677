// Mixin for generating new styles
@mixin btn-styles($btn-color, $btn-states-color) {
  border-color: $btn-color;
  color: $btn-color;
  
  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &:active:hover,
  &.active:focus,
  &.active:hover,
  .open > &.dropdown-toggle {
    background-color: $transparent-bg;
    color: $btn-states-color;
    border-color: $btn-states-color;
  }
  
  &.disabled,
  &:disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $transparent-bg;
          border-color: $btn-color;
    }
  }
  

  &.btn-fill {
    color: $white-color;
    background-color: $btn-color;
    @include opacity(1);  

    &:hover,
    &:focus,
    &:active,
    &.active,
    .open > &.dropdown-toggle{
        background-color: $btn-states-color;
        color: $white-color;
    }
    
    .caret{
        border-top-color: $white-color;
    }
  }
  
  .caret{
      border-top-color: $btn-color;
  }
}


@mixin btn-size($padding-vertical, $padding-horizontal, $font-size, $border){
   font-size: $font-size;
   border-radius: $border;
   padding: $padding-vertical $padding-horizontal;
    
   &.btn-round{
       padding: $padding-vertical + 1 $padding-horizontal;
   }
   
   &.btn-simple{
       padding: $padding-vertical + 2 $padding-horizontal; 
   }
    
}