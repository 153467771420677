/* You can add global styles to this file, and also import other style files */

.table-responsive{
  overflow: visible !important;
}

@media (min-width: 1502px) {
  .modal-xlg {
    width: 1500px;
  }
}

@media (min-width: 992px) and (max-width: 1502px) {
  .modal-xlg {
    width: 900px;
  }
}

.tus-border td {
  border-top-width: 6px !important;
}

.tr-big-border td {
  border-top-width: 6px !important;
}

.tr-background {
  background-color: #f9f9f9;
}

.tus-background {
  background-color: #f9f9f9;
}

.tooltip-inner{
  word-wrap: break-word;
  white-space: pre-line;
}

.panel {
  &.panel-tus {
    border: solid #FFB300 1px; // 600

    .panel-heading {
      background-color: #FFB300; // 600
      color: #996B00;
    }
  }

  &.panel-grey {
    border: solid #757575 1px; // 600

    .panel-heading {
      background-color: #757575; // 600
      color: #A4A4A4;
    }
  }

  &.panel-primary {
    border: solid #447DF7 1px;
  }
}

form {
  label {
    font-size: 12px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: 400;
    color: #888888;
  }
}
