/*     General overwrite     */
body .user-advanced {

}
body .user-noob{
    a:not(.btn) {
        text-decoration: underline;
    }
    .sidebar-wrapper a {
        text-decoration: none;
    }
}

#loader{
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    @include transform-translate-y(-50%);
    text-align: center;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    top: 50%;
}

body,
.wrapper{
    min-height: 100vh;
    position: relative;
}

a{
  color: $info-color;

  &:hover, &:focus{
     color: $info-states-color;
     text-decoration: none;
  }
}

a:focus, a:active,
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner{
    outline:0;
}
.ui-slider-handle:focus,
.navbar-toggle,
input:focus {
    outline : 0 !important;
}

/*           Animations              */

.animation-transition-general{
    @include transition($general-transition-time, $transition-linear);
}

.animation-transition-fast,
.sidebar .nav .collapse a > span,
.sidebar .sidebar-wrapper > .nav p,
.sidebar .user .info > a > span,
.sidebar .logo a.logo-normal,
.sidebar .user .info .collapse .nav a > span {
    @include transition($fast-transition-time, $transition-linear);
}

.animation-transition-ultra-fast{
    @include transition($ultra-fast-transition-time, $transition-ease-in);
}

.form-control,
.input-group-addon,
.tagsinput,
.navbar .alert,
.panel-collapse.collapse-hover {
    @include transition($general-transition-time, $transition-linear);
}

.sidebar .nav a,
.table > tbody > tr .td-actions .btn,
.caret{
    @include transition($fast-transition-time, $transition-ease-in);
}

.btn{
    @include transition($ultra-fast-transition-time, $transition-ease-in);
}
.fa{
    width:1em;
    text-align: center;
}
.fa-2x{
    width: 2em;
}
.fa-3x{
    width: 3em;
}
.fa-4x{
    width: 4em;
}
.fa-5x{
    width: 5em;
}
.margin-top{
    margin-top: 50px;
}

a[data-toggle="collapse"][aria-expanded="true"] .caret,
.btn[data-toggle="collapse"][aria-expanded="true"] .caret,
a.dropdown-toggle[aria-expanded="true"] .caret{
    @include rotate-180();
}

legend{
    font-size: $font-size-h4;
    font-weight: $font-weight-light;
}

.font-weight-300 {
    font-weight: 300 !important;
}

.font-weight-normal {
    font-weight: normal;
}

.font-weight-bold {
    font-weight: bold;
}

.full-screen-map{
    position:relative;
    width:100%;
    height: calc(100% - 60px);
}

.sidebar {
    z-index: 2;
}

#loader{
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    @include transform-translate-y(-50%);
    text-align: center;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    top: 50%;
}

.position-relative {
    position: relative;
}

.group-icons {
    position: relative;
    display: inline-block;
    margin-top: 8px;
    margin-right: 8px;
}

.group-icons.tiny {
    margin-top: 0px;
    margin-right: 0px;
}

.group-icons.tiny {
    margin-top: 0px;
    margin-right: 0px;
}

.group-icons.tiny .icon-corner-right {
    position: absolute;
    top: 0;
}

.icon-corner-right {
    position: absolute;
    top: -8px;
    right: -8px;
    font-size: 0.8em;
}

.sweet-alert {
    z-index: 9999;
}

/*===========================================
=            Custom CSS from A4C            =
===========================================*/


.card.card-post-it{
    background-color: #F0F4C3;
    overflow: hidden;

    .card-post-it-header {
        background-color: #C0CA33;

        h4 {
            //color: #827717;
            font-weight: bold;
            margin-top: 0;
            margin-bottom: 0.25rem !important;
        }

        p {
            margin: 0;
            color: #F0F4C3;
        }
    }

    .card-post-it-content {
        max-height: 200px;
        overflow-y: auto;

        scrollbar-color: #C0CA33 #F0F4C3;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 15px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #C0CA33;
            border-radius: 100px;
            border: 5px solid transparent;
            background-clip: content-box;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #9E9D24;
        }
    }

    .btn {
        color: #827717;
    }

    hr {
        border-color: #827717;
        margin: 0;
    }
}


.color-client{
    color: $color-client;
}
.color-prospect{
    color: $color-prospect;
}
.color-opportunity{
    color: $color-opportunity;
}

.bg-client{
    background-color: $color-client;
}
.bg-prospect{
    background-color: $color-prospect;
}
.bg-opportunity{
    background-color: $color-opportunity;
}
.bg-transparent {
    background-color: transparent;
}
.bg-white {
    background-color: #fff !important;
}

tr {
    // Love -> Pink
    // Blue Grey -> Blue Grey
    // Primary -> Indigo
    // Info -> Light Blue
    // Success -> Light Green
    // Sunny -> Yellow
    // Warning -> Orange
    // Danger -> Red

    &.bg-love-100 {
        background-color: #F8BBD0 !important;
        color: #E81D63 !important;
    }

    &.bg-blue-grey-100 {
        background-color: #CFD8DC !important;
        color: #939393 !important;
    }

    &.bg-primary-100 {
        background-color: #C5CAE9 !important;
        color: #4858BA !important;
    }

    &.bg-info-100 {
        background-color: #B3E5FC !important;
        color: #0DACF5 !important;
    }

    &.bg-success-100 {
        background-color: #DCEDC8 !important;
        color: #88C244 !important;
    }

    &.bg-sunny-100 {
        background-color: #FFF9C4 !important;
        color: #FFE710 !important;
    }

    &.bg-warning-100 {
        background-color: #FFE0B2 !important;
        color: #FF9A05 !important;
    }

    &.bg-danger-100 {
        background-color: #FFCDD2 !important;
        color: #FF152C !important;
    }

    &.bg-love-600 {
        color: #EC6898 !important;
        background-color: #D81B60 !important;
    }

    &.bg-blue-grey-600 {
        color: #7C98A5 !important;
        background-color: #546E7A !important;
    }

    &.bg-primary-600 {
        color: #707DCF !important;
        background-color: #3949AB !important;
    }

    &.bg-info-600 {
        color: #48C2FD !important;
        background-color: #039BE5 !important;
    }

    &.bg-success-600 {
        color: #4A6B28 !important;
        background-color: #7CB342 !important;
    }

    &.bg-sunny-600 {
        color: #B69402 !important;
        background-color: #FDD835 !important;
    }

    &.bg-warning-600 {
        color: #975400 !important;
        background-color: #FB8C00 !important;
    }

    &.bg-danger-600 {
        color: #F29B99 !important;
        background-color: #E53935 !important;
    }
}

.italic {
    font-style: italic;
}

.example {
    font-size: 0.8em;
    color: #888;
}

[ng-reflect-router-link]:hover{
    cursor:pointer;
}

.pointer:hover {
    cursor: pointer;
}
.move:hover {
    cursor: move;
}


.btn-transparent,
.btn-transparent:hover {
    padding-right: 5px;
    padding-left: 5px;
    background-color: transparent !important;
    border-color: transparent !important;
}

.main-panel .main-content{
    padding-left: 0;
    padding-right: 0;
}

.text-white {
    color: #fff !important;
}

.text-black {
    color: #000 !important;
}

.text-gray {
    color: #666 !important;
}

.text-little {
    font-size: 0.9em;
}

.text-tiny {
    font-size: 0.7em;
}

.bg-dark {
    background-color: #333 !important;
}
.bg-moderate-dark {
    background-color: #444 !important;
}

.bg-grey {
    background-color: rgba(34,42,66,.05);
}
.bg-extra-light {
    background-color: #f6f6f6;
}

// .panel {
//     border: solid 1px;
// }
// .panel-heading{
//     padding: 10px 15px;
// }
// .panel-warning {
//     border-color: #faebcc;
// }

.form-horizontal {
    .control-label.control-label-sm {
        padding-top: 6px !important;
    }

    .form-control.input-sm + .form-control-feedback {
        margin-top: -14px;
    }

    .form-control-static.form-control-static-sm,
    .form-control-static.form-control-static-small{
        padding-top: 1px !important;
    }
}

input[type="checkbox"].form-control {
    width: initial;
}

.breadcrumb{
    margin-bottom: 0;
}
.main-panel .main-content{
    padding-top: 0;
}

.padding-top-0,
.form-horizontal .control-label.padding-top-0 {
    padding-top: 0 !important;
}

.btn-logout {
    cursor: pointer;
}

.table-condensed .btn-simple.btn-icon {
    padding: 0;
}

.tooltip-hover {
    position: relative;
    display: inline-block;
}

.tooltip-hover .tooltiptext {
    display: block;
    visibility: hidden;
    max-width: 500px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 1px 5px;
    white-space: nowrap;
    text-transform: none;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip-hover:hover .tooltiptext {
    visibility: visible;
}

.tooltip-hover .tooltiptext {
    top: -1px;
    left: 105%;
}

.table-tickets-number {
    width: 12%;
}
.table-tickets-project {
    width: 16%;
}
.table-tickets-description {
    width: 30%;
}
.table-tickets-beginning {
    width: 12%;
}
.table-tickets-end {
    width: 12%;
}
.table-tickets-details {
    width: 8%;
}

.isEaster {
    background-image: url('/assets/img/a4c-background.png');
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: bottom right;
}

.pac-container {
    z-index: 1054 !important;
}

.settings {
    padding: 5px;
    // background-color: #f9f9f9;
    h4 {
        margin: 10px 0;
    }
    .card-body {
        padding: 0 24px !important;
    }
}

.card-projects-list .card-header,
.card-projects-list .card-footer {
    padding: 5px 15px;
}

.card-projects-list .card-header {
    font-size: 1.2em;
}

.card-projects-list-name,
.card-projects-list-number,
.card-projects-list-customer {
    width: 30%;
}

.card-projects-list-actions {
    width: 10%;
}

.card-projects-list .card-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-projects-list .card-footer ul {
    display: inline-block;
    padding: 0;
}

.card-projects-list .card-footer li {
    display: inline-block;
    margin: 0 3px;
}

.card-projects-list .card-footer button {
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 50%;
    background-color: #fff;
    color: #797979;
    border: 1px solid #ddd;
    box-shadow: none;
}

.card-projects-list .card-footer button:not(:disabled):not(.active):hover {
    background-color: #f0efef;
}

.card-projects-list .card-footer button:disabled {
    color: rgb(197, 197, 197);
    border-color: rgb(197, 197, 197);
}

.card-projects-list .card-footer button.active {
    background-color: #23ccef;
    border-color: #23ccef;
    color: #fff;
}

.border-gray-light {
    border: 1px solid #eee;
}

.border-top-black {
    border-top: 3px solid #E3E3E3 !important;
}

.border-top-gray {
    border-top: 3px solid #aaa !important;
}

.border-bottom {
    border-bottom: 1px solid #000;
}

.border-bottom-gray-light {
    border-bottom: 1px solid #eee;
}

.border-bottom-blue-light {
    border-bottom: 3px solid #D6D6FF;
}

.services-list-title {
    margin-bottom: 15px;
}

.services-list-title h5 {
    margin-bottom: 0 !important;
}

.alert.animated.fadeInDown {
    // z-index: 1100 !important;
    z-index: 9999 !important;
}

.listSettingsValues .list-group-item {
    padding: 0;
}

.listSettingsValues .list-group-item span {
    padding: 10px 15px;
}

.listSettingsValues .list-group-item span:first-child {
    border-right: 1px solid #ddd;
}

.bg-lightgrey {
    background-color: #f6f6f6;
}

.area51-reason {
    margin-top: 5px !important;
}

.settings .card-body.option-edit {
    padding: 5px 12px !important;
}

.settings .card-body.option-edit hr {
    width: 50%;
    border-color: #eee;
}

.form-options .btn-icon {
    padding-left: 0;
}

.swal-area51 {
    top: 35% !important;
}

.swal-wide {
    width: 800px !important;
}

.btn-search-add-document {
    display: block;
    text-align: left;
}

.text-left-important {
    text-align: left !important;
}

.text-center-important {
    text-align: center !important;
}

.table-bigboy .text-center {
    text-align: center !important;
}

.btn.btn-goBack {
    // margin: 7px 15px 7px 0 !important;
    // padding: 4px 12px;
}

.form-control.input-text-before {
    display: inline;
    margin-left: 5px;
    width: calc(100% - 45px);
}

.add-certif-stuff {
    margin-top: 10px;
}

.ul-input-list {
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 9999;
    width: 100%;
    padding-right: 15px;
    padding-left: 6px;
}

@media screen and (min-width: 768px) {
    .modal-dialog.width-1500 {
        width: 1500px !important;
        max-width: 1500px !important;
    }
    .modal-dialog.width-1200 {
        width: 1200px !important;
        max-width: 1200px !important;
    }
    .modal-dialog.width-1000 {
        width: 1000px !important;
        max-width: 1000px !important;
    }
    .modal-dialog.width-900 {
        width: 900px !important;
        max-width: 900px !important;
    }
}

.add-document-actions {
    min-width: 65px;
    padding-right: 0 !important;
}

.add-document-actions a,
.add-document-actions i {
    display: inline !important;
}

.display-inline {
    display: inline !important;
}

.display-inline-block {
    display: inline-block !important;
}

.display-none {
    display: none !important;
}

.card form .technologiesRow [class*="col-"] {
    padding: 0 6px !important;
}

.card form .technologiesRow input {
    height: initial !important;
}

.btn-link-categories-product {
    margin-top: 10px;
}

.form-control.form-control-inline {
   width: auto;
}

.table-condensed > tbody > tr > td {
    padding: 6px 4px;
}

.table-contract-informations td:first-of-type {
    width: 33%;
}

.table-contract-informations td:last-of-type {
    width: 67%;
}

.table-contract-offers th:first-of-type,
.table-contract-offers td:first-of-type {
    width: 5%;
}

.table-contract-offers th:nth-of-type(2),
.table-contract-offers td:nth-of-type(2) {
    width: 25%;
}

.table-contract-offers th:nth-of-type(3),
.table-contract-offers td:nth-of-type(3) {
    width: 10%;
}

.table-contract-offers th:nth-of-type(4),
.table-contract-offers td:nth-of-type(4) {
    width: 10%;
}

.table-contract-offers th:nth-of-type(5),
.table-contract-offers td:nth-of-type(5) {
    width: 10%;
}

.table-contract-offers th:nth-of-type(6),
.table-contract-offers td:nth-of-type(6) {
    width: 30%;
}

.table-contract-offers th:last-of-type,
.table-contract-offers td:last-of-type {
    width: 10%;
}

.fc-resource-area {

    .fc-cell-content {
        text-align: center;
    }
}

.form-control-inline{
  display: inline-block;
  width: auto;
}

.buttons-width button {
    width: 100%;
    text-align: left;
}

.width-100 {
    width: 100% !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.btn-group {
    margin: 5px;
}

input[type=file].input-file-hidden {
    display: none;
}

.modal {
    overflow: auto !important;
}

.modal-dialog-800w {
    width: 800px !important;
}

.modal-dialog-1000w {
    width: 1000px !important;
}

.card .form-group label {
    font-size: 12px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: 400;
    color: #888;
}

.perfect-scrollbar-off .sidebar .sidebar-wrapper,
.perfect-scrollbar-off .main-panel{
    overflow-y: auto;
    overflow-x:hidden;
}

.wrapper .sidebar-wrapper,
.wrapper .main-panel{
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: transparent;
        border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #C1C1C1;
        border-radius: 6px;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }
}

.navbar .navbar-nav > li .bootstrap-switch {
    margin: 16px 3px;
}

.contractKeywords {
    display: flex;
    border-bottom: 1px solid #aaa;
}

.contractKeywords .col {
    display: flex;
    flex-direction: column;
}

.flex {
    display: flex !important;
}

.flex-vertical-center {
    align-items: center;
}

.flex-pull-right {
    margin-left: auto !important;
}

.flex-direction-column {
    display: flex;
    flex-direction: column;
}

.align-items-center {
    display: flex;
    align-items: center;
}

.align-items-start {
    display: flex;
    align-items: flex-start;
}

.justify-content-left {
    display: flex;
    justify-content: left;
}

.justify-content-right {
    display: flex;
    justify-content: right;
}

.justify-content-center {
    display: flex;
    justify-content: center;
}

.justify-content-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-direction-column {
    display: flex;
    flex-direction: column;
}

.modal-top-150 {
    top: 150px;
}

.line-green {
    border-left: 5px solid rgb(67, 214, 22);
}

.line-red {
    border-left: 5px solid rgb(214, 22, 22);
}

.bg-danger-real {
    background-color: #ff3547 !important;
}

.bg-warning-light {
    background-color: #ffe8bb !important;
}

.bg-warning-real {
    background-color: #fc9003 !important;
}

.text-warning-real {
    color: #fc9003;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.border-blue {
    border: 1px solid #11bfe3;
}

.clearfix {
    clear: both;
}

.modal-big {
    .modal-dialog{
        max-width: 800px;
        width: 800px;
    }
}

.textarea-no-resize {
    resize: none;
}

.textarea-width-100 {
    width: 100%;
}

.border-radius-0 {
    border-radius: 0 !important;
}

.border-radius-10 {
    border-radius: 10px !important;
}

.min-width-800 {
    min-width: 800px;
}

.min-width-1000 {
    min-width: 1000px;
}

.min-width-1200 {
    min-width: 1200px;
}

.footer-nav {
    z-index: 2;
}

.highlight-element {
  border-radius: 3px;
  animation: highlightOrange 2000ms ease-out;
}

@keyframes highlightOrange {
    0% {
        background-color: #f39c12;
    }
    100% {
        background-color: #fff;
    }
}

.text-color .text-illuminate {
    color: #b2e469;
}
.text-color .text-illuminate-hover {
    color: #bbb;
}
.text-color:hover .text-illuminate-hover {
    color: #b2e469;
}

.list-comma span:last-child .separator-comma {
    display: none;
}

.bg-info-important {
    background-color: #d9edf7 !important;
}

.ng2-tag-input.regular-theme tag delete-icon svg {
    opacity: 1 !important;
    right: 10px !important;
}

.ng2-tag-input.regular-theme tag delete-icon path {
    fill: #f00 !important;
}

.separator-vertical-gray-light {
    display: inline-block;
    min-height: 20px;
    border: 1px solid #ddd;
}

.chip-dark {
    background-color: #aaa !important;
    color: #666 !important;
}

.bootstrap-datetimepicker-widget {
    z-index: 9999 !important;
}

.min-width-180px {
    min-width: 180px;
}

.height-33px {
    height: 33px;
}

.max-height-500px {
    max-height: 500px;
}

.opportunity-self-concerned {
    background-color: #f9d77e !important;
}

.btn-14px {
    font-size: 14px !important;
    line-height: 14px !important;
}

.zindex-modal-down {
  z-index: 9998 !important;
}

.zindex-modal-up {
  z-index: 9999 !important;
}

.table-horizontal-extended {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.table-horizontal-extended {
    > thead, > tbody, > tfoot {
        > tr {
            > th, > td {
                max-width: 100%;
                white-space: nowrap;
                padding: 5px 10px !important;
            }
        }
    }
}

.white-space-pre-wrap {
    white-space: pre-wrap;
}

.custom-input-group span {
    max-height: 40px;
    font-size: 0.8em;
    color: #666;
    font-style: italic;
    background: #fafafa;
    border-left: 1px solid #e9e9e9 !important;
}

.input-group-addon-gray {
    background-color: #f3f3f3 !important;
    border-left: 1px solid #e3e3e3 !important;
}

.fa-stack-1-8x {
    font-size: 1.8em;
}

.text-color-gray {
    color: #666 !important;
}

.has-warning ng-select .ng-select-container {
    border-color: #FB8C00; // 600 red
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #FFE0B2; // 100 red
}

ng-select.ng-invalid.ng-touched .ng-select-container {
    border-color: #E53935; // 600 red
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #FFCDD2; // 100 red
}

input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
    border-color: #E53935; // 600 red
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #FFCDD2; // 100 red
}

div.show-valid-on-changed,
form.show-valid-on-changed {
    input.ng-valid.ng-dirty,
    textarea.ng-valid.ng-dirty {
        border-color: #7CB342; // 600 green
    }

    ng-select.ng-valid.ng-dirty .ng-select-container {
        border-color: #7CB342; // 600 green
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #DCEDC8; // 100 green
    }
}

.ng-select-input-sm .ng-select-container {
    height: 30px !important;
    min-height: 30px !important;
}

.ng-select-inline {
    display: inline-block !important;
}

.ng-select-w300 {
    width: 300px !important;
    max-width: 300px !important;
}

[node-id] rect {
    fill: #ffffff;
}

[node-id] circle {
    fill: #aeaeae;
}

[node-id] text {
    fill: #aeaeae;
}

.org-departement > rect {
    fill: #fff5d8;
}

.org-departement > text {
    fill: #ecaf00;
}

.org-departement > circle {
    fill: #ecaf00;
}

.perfect-scrollbar-off .main-panel {
    /*overflow-y: hidden;*/
    overflow-x: hidden;
}

.form-horizontal .checkbox:first-child {
    margin-top: 0 !important;
}

.customcheck {
    display: block;
    position: relative;
    height: 20px;
    width: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.customcheck input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #bbb;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.customcheck:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.customcheck input:checked ~ .checkmark {
    background-color: #02cf32;
    border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.customcheck input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.customcheck .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.dropdown-menu-carret-right::before,
.dropdown-menu-carret-right::after {
    left: auto !important;
    right: 12px !important;
}

.dropdown-menu-carret-left::before,
.dropdown-menu-carret-left::after {
    left: 12px !important;
    right: auto !important;
}

@media screen and(max-width: 767px) {
    .table-horizontal-mobile {
        // display: block;
        overflow-x: auto;
        white-space: nowrap;
        width: 767px;
    }

    .table-horizontal-mobile {
        > thead, > tbody, > tfoot {
            > tr {
                > th, > td {
                    max-width: 100%;
                    white-space: nowrap;
                    padding: 5px 10px !important;
                    overflow: hidden;
  text-overflow: ellipsis;
                }
            }
        }
    }

    .modal-backdrop {
        /* bug fix - no overlay */
        display: none;
    }
}

/*
 * Ng-Select dark-mode
 */
.dark-mode {
    .ng-select.ng-select-opened > .ng-select-container,
    .ng-select .ng-select-opened > .ng-select-container,
    .ng-select .ng-select-container {
        background: transparent !important;
    }

    // Dropdown
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        background-color: #1e1e2f !important;
        color: #bdbdbd !important;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
        background-color: lighten(#1e1e2f, 5%) !important;
        color: #bdbdbd !important;
    }

    // Dropdow selected hover or select option(s)
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
        background-color: lighten(#1e1e2f, 15%) !important;
        color: #bdbdbd !important;
    }

    // Dropdow option selected
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
        background-color: lighten(#1e1e2f, 7.5%) !important;
        color: #bdbdbd !important;
    }
}

.cursor-ptr {
    cursor: pointer;
}

.table:not(.table-todos) .form-group {
    margin: 0 !important;
}

.modal-body-scroll {
    overflow-y: auto !important;
    max-height: calc(100vh - 200px) !important;
}

.block-gray {
    border: 1px solid #E3E3E3;
    background-color: #eee;
}

.upload-container {
    input[type="file"] {
        display: none;
    }

    .upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;

        .btn-upload {
            // margin-top: 75px;
            // margin-bottom: 75px;
            padding-top: 75px;
            padding-bottom: 75px;
            border: dashed 2px #bdbdbd;
        }
    }

    .upload-btn-wrapper input[type=file] {
        font-size: 100px;
        height: 178px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
}

.dropdown-menu-right.dropdown-menu:before,
.dropdown-menu-right.dropdown-menu:after {
    left: unset !important;
    right: 12px !important;
}

.dropdown-menu-left.dropdown-menu:before,
.dropdown-menu-left.dropdown-menu:after {
    left: 12px !important;
    right: unset !important;
}

.table td {
    position: relative !important;
}

.rotate-table tr, .rotate-table td, .rotate-table th {
    position: relative;
    border: 1px solid #e3e3e3;
    border-collapse: collapse;
}

.rotate-th span {
    transform-origin: 0 50%;
    transform: rotate(-90deg);
    white-space: nowrap;
    display: block;
    position: absolute;
    bottom: 6px;
    left: 50%;
}

.card .special-title {
    font-size: 11px;
    text-transform: uppercase;
    color: #9A9A9A;
}

.card .special-text {
    font-weight: bold;
}

.card .special-title-lg {
    font-size: 13px;
    text-transform: uppercase;
    color: #9A9A9A;
}

.card .special-text-lg {
    font-size: 16px;
    font-weight: bold;
}

@media (min-width: 1292px) {
    .modal-xl {
        width: 1200px
    }
}

.label-default {
    color: rgb(19, 19, 19);
}

.label-primary {
    color: rgb(0, 21, 81);
}

.label-success {
    color: rgb(0, 84, 0);
}

.label-warning {
    color: rgb(139, 73, 0);
}

.label-danger {
    color: rgb(115, 0, 0);
}

.list-link {
    display: initial !important;
}

/** Items **/
.step-projects-main-button {
    width: 100%;
    text-align: left;
}

.step-projects-main-button .group-icons {
    width: 20px;
}

.item-main-title-font-weight,
.item-main-title-font-weight td {
    font-weight: 800;
}

.item-comment-italic {
    font-style: italic;
    font-size: 0.8em;
}

.item-variant {
    border-left: 5px solid #aa3333;
}

.item-document-ongoing {
    border-left: 5px solid #c48600;
}

.item-document-sent {
    border-left: 5px solid #3333aa;
}

.item-document-validated {
    border-left: 5px solid #008116;
}

.item-document-validated-sent {
    border-left: 5px solid #00e226;
}

.item-transparent {
    opacity: 0.3;
}

.item-bar {
    display: inline-block;
    width: 5px;
    height: 100%;
}

.item-bar-variant {
    background-color: #aa3333;
}

.item-bar-document-ongoing {
    background-color: #c48600;
}

.item-bar-document-sent {
    background-color: #3333aa;
}

.item-bar-document-validated {
    background-color: #008116;
}

.item-document-self-ongoing {
    color: #c48600;
}

.item-document-self-sent {
    color: #3333aa;
}

.item-document-self-validated {
    color: #008116;
}

.table-striped-big-border tbody tr td {
    border-top-width: 6px !important;
}

.table-striped-big-border tbody tr:nth-of-type(odd) {

    background-color: #f9f9f9;
}

.dark-mode {
    .table-striped-big-border tbody tr:nth-of-type(odd) {
        background-color: rgba(34,42,66,.05);
    }
}

.project-profitability-block {
    display: flex;
}

.project-profitability {
    flex: 1;
    vertical-align: top !important;

    .title {
        text-transform: uppercase;
        text-align: center;
        font-size: 1.2em;
    }
}

// .project-profitability-ins {
//     box-shadow: 5px 0 5px -2px #888;
// }

.project-profitability-outs {
    box-shadow: -5px 0 5px -2px #888;
}

.project-profitability-subblock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: auto;
}

.main-menu {
    border-bottom: 2px solid #ddd;
    text-transform: uppercase;
    background: #eee;
}

.main-menu > li > a {
    margin: 0 15px 0 15px !important;
    padding: 0 0 10px 0 !important;
    font-weight: bold !important;
    color: #222 !important;
}

.main-menu > li.active > a {
    color: #27b6d3 !important;
    border-bottom: 3px solid #27b6d3;
}

.sub-menu {
    border-right: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
    border-left: 2px solid #ddd;
}

.tab-pane {
    padding-top: 0 !important;
}

.add-choices {
    font-size: 0.9em;
    font-style: italic;
}

.card-header-title {
    padding: 15px !important;
    background-color: #f6f6f6;
    border-bottom: 1px solid #ddd;
}

.list-group-item-separator div:first-child {
    min-width: 60px;
    background: #eee;
    border-right: 1px solid #ccc;
}

.container-bubble-number {
    position: relative;
}

.bubble-number {
    position: absolute;
    top: -5px;
    right: 0px;
    display: block;
    width: 16px;
    height: 16px;
    padding: 3px;
    font-size: 0.7em;
    line-height: 9px;
    border-radius: 50%;
    background: #f00;
    color: #fff;
}

.sidebar-mini .sidebar .logo .simple-text {
    line-height: 38px;
}

.sidebar-mini .sidebar .logo-img {
    width: 38px;
    max-width: 38px;
}

.sidebar-mini .sidebar .logo-img img {
    width: 38px;
}

.subtitle {
    text-transform: uppercase;
    background-color: #eee;
}

.btn-input-group-multiple {
    padding: 4px !important;
    border-color: #e3e3e3;
}

.input-group-btn:last-child > .btn.set-date-now:not(:last-child):not(.dropdown-toggle) {
    border-right: 1px solid #e3e3e3 !important;
}

.input-group-btn:last-child > .btn.set-date-now:not(:last-child):not(.dropdown-toggle):hover,
.input-group-btn:last-child > .btn.set-date-now:not(:last-child):not(.dropdown-toggle):focus {
    border-right-color: #aaa !important;
}

.input-group input:focus + .input-group-btn .btn-input-group-multiple {
    border-color: #aaa;
}

.ng-select-multiple-footer-options .ng-dropdown-panel .ng-dropdown-footer {
    padding: 0 !important;
}

.ng-select-multiple-footer-options .ng-dropdown-panel .ng-dropdown-footer p {
    padding: 5px 10px !important;
}

.ng-select-multiple-footer-options .ng-dropdown-panel .ng-dropdown-footer p:nth-of-type(2) {
    border-top: 1px solid #ddd;
}

.fa-tiny {
    font-size: 0.7em;
    line-height: .75em;
    vertical-align: 20%;
}