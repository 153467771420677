
	$color1 : #BDBDBD; // Kinda white: Fonts etc
	$color2 : #666;    // First grey: borders etc;
	$color3 : #27293D; // Second grey: panels etc;
	$color4 : #27293D; // Third grey: footer, headers;
	$color5 : #1E1E2F; // Kinda black: main background;


	/*====================================
	=            Main wrapper            =
	====================================*/
	* {
		color: $color1;
	}

	.full-page[data-color="azure"]:after {
	    background: $color5;
	    background: linear-gradient(to bottom, $color5 0%, rgba(64, 145, 255, 0.6) 100%);
	}

	.wrapper .sidebar-wrapper,
	.wrapper .main-panel{
	    &::-webkit-scrollbar-track {
	        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	        background-color: transparent;
	        border-radius: 6px;
	    }

	    &::-webkit-scrollbar-thumb {
	        background-color: $color3;
	        border-radius: 6px;
	    }

	    &::-webkit-scrollbar {
	        width: 6px;
	        background-color: transparent;
	    }
	}
	.sidebar .logo,
	.sidebar .sidebar-wrapper{
		box-shadow: none;
	}

	.sidebar,
	.bootstrap-navbar{
	    // colors for the sidebar with an image

	    background: $color5;

	    &[data-color="blue"]{
	    	&:after{
	    		background: $color4;
	    	}
	    	.nav i {
	    		color: $color-blue;
	    	}
	    }
	    &[data-color="azure"]{
	    	&:after{
	    		background: $color4;
	    	}
	    	.nav i {
	    		color: $color-azure;
	    	}
	    }
	    &[data-color="green"]{
	    	&:after{
	    		background: $color4;
	    	}
	    	.nav i {
	    		color: $color-green;
	    	}
	    }
	    &[data-color="orange"]{
	    	&:after{
	    		background: $color4;
	    	}
	    	.nav i {
	    		color: $color-orange;
	    	}
	    }
	    &[data-color="red"]{
	    	&:after{
	    		background: $color4;
	    	}
	    	.nav i {
	    		color: $color-red;
	    	}
	    }
	    &[data-color="purple"]{
	    	&:after{
	    		background: $color4;
	    	}
	    	.nav i {
	    		color: $color-purple;
	    	}
	    }
	}

	.main-panel{
	    background: $color5;
	}

	.breadcrumb{
		background: transparent;
	}

	.footer, .navbar{
	    background: $color4;
	}

	/*=============================
	=            Cards            =
	=============================*/

	.card{
		background: $color3;
		// border: solid $color2 1px;

		.footer{
			border-top: solid 1px #2b3553;
		}

		.header{
			border-bottom: solid 1px #2b3553;
		}

		.footer, .header{
			color: rgba(255,255,255,0.3);
			background-color: inherit;
		}

		&.card-post-it{
			background: rgba(102, 51, 153, 0.5);
			.header,
			.footer,
			.content{
				background-color: transparent;
			}

			.header {
			    border-bottom: rgba(102, 51, 153, 1);
			}

			.footer {
				border-top: rgba(102, 51, 153, 1);
			}
		}

	}

	.card.card-plain{
		border: none;
	}

	/*==============================
	=            Panels            =
	==============================*/

	.panel {

		color: $color1 ;
		background-color: $color3 ;

		.panel-heading{
			border: none;
			color: white ;
		}

		.panel-body {
		}

		&.panel-warning{
			border: solid #ff9f89 1px;
			.panel-heading{
				background-color: #ff9f89;
			}
		}

		&.panel-danger{
			border: solid #fd77a4 1px;
			.panel-heading{
				background-color: #fd77a4;
			}
		}

		&.panel-info{
			border: solid #419ef9 1px;
			.panel-heading{
				background-color: #419ef9;
			}
		}

		&.panel-primary{
			border: solid #e66ad2 1px;
			.panel-heading{
				background-color: #e66ad2;
			}
		}

		&.panel-success{
			border: solid #00bf9a 1px;
			.panel-heading{
				background-color: #00bf9a;
			}
		}


	}

	/*=====================================
	=            Notifications            =
	=====================================*/
	.alert-primary {
	    color: #e14eca;
	    background-color: #e66ad2;
	    border-color: #e66ad2;
	}

	.alert-info {
	    color: #1d8cf8;
	    background-color: #419ef9;
	    border-color: #419ef9;
	}

	.alert-success {
	    color: #00f2c3;
	    background-color: #00bf9a;
	    border-color: #29f4cd;
	}

	.alert-warning {
	    color: #ff8d72;
	    background-color: #ff9f89;
	    border-color: #ff9f89;
	}

	.alert-danger {
	    color: #fd5d93;
	    background-color: #fd77a4;
	    border-color: #fd77a4;
	}

	.alert {

		* {
			color: white;
		}

		button.close {
			background-color: transparent;
		}

	}


	/*==============================
	=            Tables            =
	==============================*/

	.table {
		background-color: inherit;

		.thead {
			border-color: $color2;

			tr > th {
				border-bottom: solid 1px $color2;
			}

		}



	}

	.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
	    border-color: hsla(0,0%,100%,.1);
	}

	.table-hover>tbody>tr:hover{
		background-color: inherit;
	}

	.table-striped tbody tr:nth-of-type(odd) {
		background-color: rgba(34,42,66,.05);
	}

	/*==============================
	=            Titles            =
	==============================*/

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.card .title,
	.card .header,
	legend{
		color: #dedede;
	}

	legend {
		border-bottom: solid 1px $color2;
	}

	/*==============================
	=            Inputs            =
	==============================*/

	input, textarea, select {
		background-color : inherit;
		color: $color1;
		border-color: $color2;
	}

	.input-group-addon,
	.input-group-btn .btn-default:not(.btn-fill) {
		background-color: inherit;
		border: solid 1px $color2;
		border-right: none;
	}

	.form-control {
	    background-color: inherit;
	    border: 1px solid $color2;
	    color: $color1;
	}

	.form-control:focus {
	    background-color: inherit;
	    border: 1px solid $color2;
	    color: $color1;
	}

	.form-control option,
	.form-control optgroup{
		background-color: $color5;
	}

	/*====================================
	=            FullCalendar            =
	====================================*/

	.fc-unthemed th,
	.fc-unthemed td,
	.fc-unthemed thead,
	.fc-unthemed tbody,
	.fc-unthemed .fc-divider,
	.fc-unthemed .fc-row,
	.fc-unthemed .fc-popover{
		border-color: $color2;
	}

	.fc-unthemed .fc-divider,
	.fc-unthemed .fc-popover .fc-header{
		background-color: $color2;
	}

	.fc-unthemed .fc-today{
		background-color: #252E49;
	}

	.fc .fc-state-active,
	.fc .fc-state-active:focus,
	.fc .fc-state-active:hover,
	.fc .fc-state-active:active:focus,
	.fc .fc-state-active:active:hover,
	.fc .fc-state-active:active {
	    background-color: $color5;
	    color: $color1;
	}

	.fc button[disabled], .fc button[disabled]:focus, .fc button[disabled]:hover {
	    background-color: $color5;
	    border-color: $color1;
	}


	/*==============================
	=            Modals            =
	==============================*/
	.modal-content{
		background-color: $color3;
	}

	.modal-header{
		border-bottom: solid 1px #2b3553;
	}
	.modal-footer{
		border-top: solid 1px #2b3553;
	}

	/*=============================
	=            Lists            =
	=============================*/

	.list-group-item{
		background-color: transparent;
		border: solid $color2 1px;
	}
