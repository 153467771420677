/*! =========================================================
 *
 * Light Bootstrap Dashboard PRO Angular CLI - V1.2.1 (LBD V1.3.0)
 *
 * =========================================================
 *
 * Copyright 2017 Creative Tim
 * Available with purchase of license from  https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-angular2
 *
 *
 * ========================================================= */


// variables and mixins
@import "lbd/variables";
@import "lbd/mixins";


//plugins css
@import "lbd/plugins/animate";
@import "lbd/plugins/sweetalert2";

@import "lbd/plugins/jquery.jvectormap";
@import "lbd/plugins/bootstrap-table";
//@import "lbd/plugins/datatables.net";
@import "lbd/plugins/fullcalendar";
//@import "lbd/plugins/chartist";
@import "lbd/plugins/perfect-scrollbar";
@import "lbd/plugins/bootstrap-select";
@import "lbd/plugins/fixed-plugin";
//@import "lbd/plugins/nouislider";
@import "lbd/plugins/tags";


// Core CSS
@import "lbd/typography";
@import "lbd/sidebar-and-main-panel";
@import "lbd/buttons";
@import "lbd/misc";
@import "lbd/inputs";
@import "lbd/progress-bars";
@import "lbd/sliders";
@import "lbd/forms";
@import "lbd/alerts";
@import "lbd/tables";
@import "lbd/tooltips-and-popovers";
@import "lbd/checkbox-radio-switch";
@import "lbd/navbars";
@import "lbd/footers";


// Fancy Stuff
@import "lbd/social-buttons";
@import "lbd/dropdown";

@import "lbd/cards";

@import "lbd/collapse";
@import "lbd/carousel";

@import "lbd/plugins/datetime-picker";

// SCSS for Pages
@import "lbd/pages.scss";

// Responsive adjustments
@import "lbd/responsive";
@import "lbd/tabs-navs-pagination";

// Bootstrap 3 extension 
@import "lbd/bootstrap-3-ext";

// Darkmode
.dark-mode{
	@import "lbd/darkmode";
}
